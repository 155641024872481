import React from 'react'
import Layout from "../../../components/Layout";
import CaseStudyHeader from "../../../components/CaseStudyHeader";
import Cta from "../../../components/Cta";

import body from './index.md'

import River from "../../../images/river.png"

const ReactMarkdown = require('react-markdown')

function RiverCaseStudy() {
    return (
        <Layout animated={true} >

          <div className="case-study-page-header-container">
            <div className="case-study-page-header-title">Case Study</div>
          </div>

            <CaseStudyHeader 
                reverse={false}
                type={'phone'}
                color={'#fafafa'}
                textColor={'dark'}
                title={'River'}
                image={River}
                tags={['Social Music', 'Collaborative Playlists', 'Web + iOS']}
            />

            <div className="case-studies-content-container">
              <ReactMarkdown source={body} />
            </div>

            <Cta title="see more of our work" buttonText="portfolio" link="/portfolio"/>
        </Layout>

    
    )
}

export default RiverCaseStudy;
